import React from "react";
import Companies from "../components/companies/Companies";
import Cta from "../components/cta/Cta";
import { Helmet } from "react-helmet";
import Navbar from "../components/navbar/Navbar";
import Footer from "../components/footer/Footer";
import SEO from "../components/seo/SEO";
import GetStartedContent from "../components/GetStartedContent";
import Layout from "../components/layout/Layout";

export default function Getstarted() {
  return (
    <Layout>
      <SEO />
      <Helmet>
        <title>Get started - /meet 4 Slack</title>
      </Helmet>
      <Navbar />
      <GetStartedContent />
      <Companies />
      <Cta />
      <Footer />
    </Layout>
  );
}
